<template>
  <div class="col-sm-12">
    <div class="payment-option-card p-3">
      <h6>Payment Options</h6>
      <p>Enter your details below to use these methods of payment</p>
      <ul class="list-unstyled d-flex mb-0">
        <!-- <li><img src="@/assets/ecommerce/card-amex.png" /></li> -->
        <li class="mx-2"><img src="@/assets/ecommerce/card-visa.png" /></li>
        <li><img src="@/assets/ecommerce/card-master.png" /></li>
      </ul>
    </div>
    <h5 class="mb-4">Your Payment Details</h5>
    <ValidationObserver v-slot="{ passes }">
      <form
        class="form-auth-small"
        @submit.prevent="passes(sendbyCard)"
        id="registration"
        ref="registration"
        autocomplete="off"
      >
        <ValidationProvider
          name="full-nameabc"
          rules="required"
          v-slot="{ errors }"
        >
          <div
            class="form-group"
            :class="{
              errors:
                (errors.length && !paybyCard.fullName) ||
                (errorArr[2] && errorArr[2].fieldName != ''),
              success:
                paybyCard.fullName &&
                errorArr[2] &&
                errorArr[2].fieldName == '',
            }"
          >
            <span class="float-label">
              <input
                type="text"
                id="fullName"
                v-model="paybyCard.fullName"
                class="form-control"
                placeholder="Full Name as Displayed on Card*"
                @input="checkNameFormat($event, 'full_name', 'Full Name', 2)"
              />
              <label for="fullName" class="control-label"
                >Full Name as Displayed on Card*</label
              >
              <div class="line"></div>
              <i
                class="fa fa-times"
                v-if="
                  errors.length &&
                  !paybyCard.fullName &&
                  errorArr[2] &&
                  errorArr[2].fieldName == ''
                "
              ></i>
              <i
                class="fa fa-check"
                v-if="
                  paybyCard.fullName &&
                  errorArr[2] &&
                  errorArr[2].fieldName == ''
                "
              ></i>
            </span>
            <span
              class="text-danger error-msg"
              v-if="errorArr[2] && errorArr[2].fieldName != ''"
              >{{ errorArr[2].errorMessage }}</span
            >
            <span class="text-danger error-msg" v-if="apiErrorCode == 'V0022-0010'">{{apiErrorMsg}}</span>
          </div>
        </ValidationProvider>
        <!-- <ValidationProvider name="Card Number" rules="required" v-slot="{ errors}">
                    <div class="form-group" :class="{'errors': errors.length && !paybyCard.cardNumber,'success':paybyCard.cardNumber}">
                        <span class="float-label">
                            <input type="text" id="cardNumber" v-model="paybyCard.cardNumber" class="form-control" placeholder="Enter Card Number*" @keypress="enterOnlyNumber($event)">
                            <label for="cardNumber" class="control-label">Enter Card Number*</label>
                            <div class="line"></div>
                            <i class="fa fa-times" v-if="errors.length && !paybyCard.cardNumber"></i>
                            <i class="fa fa-check" v-if="paybyCard.cardNumber"></i>
                            <i class="fa fa-credit-card"></i>
                        </span>
                    </div>
                </ValidationProvider> -->
        <div class="form-group">
          <span class="float-label">
            <input
              ref="cardNumInput"
              id="cardnumber"
              :data-error="cardErrors.cardNumber ? true : false"
              v-model="cardNumber"
              type="tel"
              class="form-control"
              placeholder="#### #### #### ####"
              v-cardformat:formatCardNumber
              @blur="validCardNumber"
              @paste.prevent
            />
            <label for="cardnumber" class="control-label"
              >Enter Card Number*</label
            >
            <div class="line"></div>
            <i :class="cardBrandClass" class="fa-lg" style="color: #1e9c7e"></i>
            <!--  style="color: #1e9c7e" -->
          </span>
          <span class="text-danger error-msg" v-if="apiErrorCode == 'V0022-001' || apiErrorCode == 'V0022-002'">{{apiErrorMsg}}</span>
          <span class="text-danger error-msg" v-if="cardErrors.cardNumber">{{
            cardErrors.cardNumber
          }}</span>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!-- <ValidationProvider name="expiryDate" rules="required" v-slot="{ errors }">
                            <div class="form-group datepicker" :class="{'errors': errors[0],'success':paybyCard.expiryDate && !errors[0]}">
                                <span class="float-label">
                                    <datepicker class="form-control" :bootstrap-styling="false" v-model="paybyCard.expiryDate" :typeable="true"></datepicker>
                                    <label for="cardNumber" class="control-label">Expiry Date*</label>
                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                    <i class="fa fa-check" v-if="paybyCard.expiryDate && !errors[0]"></i>
                                </span>
                            </div>
                        </ValidationProvider> -->
            <div class="form-group">
              <span class="float-label">
                <input
                  ref="cardExpInput"
                  id="card-exp"
                  :data-error="cardErrors.cardExpiry ? true : false"
                  v-model="cardExpiry"
                  maxlength="10"
                  class="form-control"
                  v-cardformat:formatCardExpiry
                  @blur="validExpireDate"
                  @paste.prevent
                />
                <label for="card-exp" class="control-label"
                  >Expiry Date(e.g-MM/YY)*</label
                >
              </span>
              <span
                class="text-danger error-msg"
                v-if="cardErrors.cardExpiry"
                >{{ cardErrors.cardExpiry }}</span
              >
              <span class="text-danger error-msg" v-if="apiErrorCode == 'V0022-003' || apiErrorCode == 'V0022-004' || apiErrorCode == 'V0022-005' || apiErrorCode == 'V0022-006' || apiErrorCode == 'V0022-007' || apiErrorCode == 'V0022-008' || apiErrorCode == 'V0022-009'">{{apiErrorMsg}}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <span class="float-label">
                <input
                  ref="cardCvcInput"
                  id="card-cvc"
                  :data-error="cardErrors.cardCvc ? true : false"
                  v-model="cardCvc"
                  class="form-control"
                  v-cardformat:formatCardCVC
                  @blur="validCvv"
                  @paste.prevent
                />
                <label for="card-cvc" class="control-label">CVV*</label>
              </span>
              <span class="text-danger error-msg" v-if="cardErrors.cardCvc">{{
                cardErrors.cardCvc
              }}</span>
              <span class="text-danger error-msg" v-if="apiErrorCode == 'V0022-0011' || apiErrorCode == 'V0022-0012'">{{apiErrorMsg}}</span>
            </div>
            <!-- <ValidationProvider name="cvv" rules="required" v-slot="{ errors}">
                            <div class="form-group" :class="{'errors': errors.length && !paybyCard.cvv,'success':paybyCard.cvv}">
                                <span class="float-label">
                                    <input type="text" v-model="paybyCard.cvv" :maxlength="3" class="form-control" id="cvv" placeholder="0.00"  @keypress="enterOnlyNumber($event)">
                                    <label for="cvv" class="control-label">CVV*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !paybyCard.cvv"></i>
                                    <i class="fa fa-check" v-if="paybyCard.cvv"></i>
                                </span>
                            </div>
                        </ValidationProvider> -->
          </div>
        </div>
        <!-- <div class="d-flex justify-content-between align-items-center m-b-20">
          <p class="mb-0">Save this account for future use.</p>
          <div class="custom-switch success-error-bg">
            <input
              type="checkbox"
              class="custom-switch-input"
              id="tamount"
              v-model="paybyCard.futureUseIs"
            />
            <label class="custom-switch-label" for="tamount">
              <span class="custom-switch-text1">Yes</span>
              <span class="custom-switch-text2">No</span>
            </label>
          </div>
        </div> -->
        <button type="submit" class="btn btn-gradient btn-lg btn-mw btn-block" :disabled="btnDesabled">
          <!--  :disabled="btnDesabled" -->
          Pay by Card
        </button>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
const { ValidationObserver, ValidationProvider } = require("vee-validate");
// import { v4 as uuidv4 } from 'uuid';
import shared from "@/shared.js";
export default {
  name: "PaybyCard",
  components: {
    ValidationObserver,
    ValidationProvider,
    // Datepicker: () => import('vuejs-datepicker'),
  },
  props: {
    checkout_country: Object,
    // merchant_details: Object,
  },
  data() {
    return {
      btnDesabled:false,
      paybyCard: {
        fullName: "",
        cardNumber: "",
        expiryDate: "",
        cvv: "",
        futureUseIs: true,
        currency: {
          name: "",//"GHS"
          flagClass: "", //"flag-icon flag-icon-gh fi-md fi-round",
        },
        amount: "",
        disabledCurrencyDropDown: true,
      },
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      cardErrors: {},
      // declaring card-brand in data{} enables card brand name/classes.
      cardBrand: null,

      // currencyOptions: [
      //   { name: "GBP", flagClass: "flag-icon flag-icon-gb fi-md fi-round" },
      //   { name: "GHS", flagClass: "flag-icon flag-icon-gh fi-md fi-round" },
      //   // { name: "USD", flagClass: "flag-icon flag-icon-us fi-md fi-round" },
      //   // { name: "RUB", flagClass: "flag-icon flag-icon-ru fi-md fi-round" },
      // ],

      // countrysOptions: [
      //   {
      //     name: "United Kingdom",
      //     flagClass: "flag-icon flag-icon-gb fi-xl fi-round",
      //     primaryNumberCode: "GB",
      //     id:"8e60ff5a-dda4-45eb-b220-9c4dfcdb32a5"
      //   },
      //   {
      //     name: "Ghana",
      //     flagClass: "flag-icon flag-icon-gh fi-xl fi-round",
      //     primaryNumberCode: "GH",
      //     id:"8ee2b557-1047-49c4-bac2-ee7bf35e24e3"
      //   },
      // ],

      errorArr: [],
      fieldName: "",
      fieldErrorMsg: "",
      apiErrorMsg: "",
      elementName: "",
      apiErrorCode: "",
      transactionId: "",
      paymenterrormsg: "",

      merchantdata:null,
    };
  },
  methods: {
    validCardNumber() {
      this.cardErrors = {};
      if (!this.$cardFormat.validateCardNumber(this.cardNumber)) {
        this.cardErrors.cardNumber = "Invalid Card Number.";
      } else {
        this.cardErrors.cardNumber = "";
      }
    },
    validExpireDate() {
      this.cardErrors = {};
      if (!this.$cardFormat.validateCardExpiry(this.cardExpiry)) {
        this.cardErrors.cardExpiry = "Invalid Expiration Date.";
      } else {
        this.cardErrors.cardExpiry = "";
      }
    },
    validCvv() {
      this.cardErrors = {};
      if (!this.$cardFormat.validateCardCVC(this.cardCvc)) {
        this.cardErrors.cardCvc = "Invalid CVV.";
      } else {
        this.cardErrors.cardCvc = "";
      }
    },

    sendbyCard() {
      this.btnDesabled = true;
      // init
      this.cardErrors = {};

      // validate card number
      if (!this.$cardFormat.validateCardNumber(this.cardNumber)) {
        this.cardErrors.cardNumber = "Invalid Card Number.";
        this.btnDesabled = false;
      }

      // validate card expiry
      if (!this.$cardFormat.validateCardExpiry(this.cardExpiry)) {
        this.cardErrors.cardExpiry = "Invalid Expiration Date.";
        this.btnDesabled = false;
      }

      // validate card CVV
      if (!this.$cardFormat.validateCardCVC(this.cardCvc)) {
        this.cardErrors.cardCvc = "Invalid CVV.";
        this.btnDesabled = false;
      }

      this.createLog(this.transactionId, 'Call Checkout');
      // console.log(this.paybyCard.expiryDate.split(" "));
      // const dob = new Date(this.paybyCard.expiryDate);
      // expiry_month: moment(dob).format('MM'),
      // expiry_year: moment(dob).format('YYYY'),
      const dob = this.cardExpiry.split("/");
      // console.log(dob);
      const getFullYear = new Date().getFullYear();
      const first2Digit = getFullYear.toString().slice(0, 2);

      const userdetail = JSON.parse(sessionStorage.getItem("userData"));

      let updateData = {
        is_token_type:false,
        amount:parseFloat(sessionStorage.getItem('amt')*100).toFixed(2),
        currency:sessionStorage.getItem('currency'),
        merchant_id:this.transactionId,
        reference:userdetail.reference,
        address_line_1:userdetail.address1, 
        address_line_2:userdetail.address2,
        city:userdetail.city, 
        state:userdetail.state, 
        zip:userdetail.zip, 
        country:sessionStorage.getItem('countrycode').toUpperCase(),
        // phone_number_code:userdetail.phonecode,
        phone_number:userdetail.phonenumber,
        email:userdetail.email,
        payment_ip:userdetail.ipaddress,//"17.0.0.1",
        challenge_indicator:"challenge_requested_mandate"
      }
      this.updatePayment(updateData);
      
      let checkoutData = {
        is_token_type:false,
        amount:parseFloat(sessionStorage.getItem('amt')*100).toFixed(2),
        currency:sessionStorage.getItem('currency'),
        merchant_id:this.transactionId,
        reference:userdetail.reference,
        card_number:this.cardNumber.replaceAll(/\s/g, ""),
        expiry_month:dob[0].split(" ").join(""),
        expiry_year:dob[1].split(" ").join("").length == 4 ? dob[1].split(" ").join("") : first2Digit + dob[1].split(" ").join(""),
        name:this.paybyCard.fullName,
        cvv:this.cardCvc,
        address_line_1:userdetail.address1, 
        address_line_2:userdetail.address2, 
        city:userdetail.city, 
        state:userdetail.state, 
        zip:userdetail.zip, 
        country:sessionStorage.getItem('countrycode').toUpperCase(),
        // phone_number_code:userdetail.phonecode, 
        phone_number:userdetail.phonenumber, 
        email:userdetail.email,
        payment_ip:userdetail.ipaddress,//"17.0.0.1",
        challenge_indicator:"challenge_requested_mandate",
        businessid: sessionStorage.getItem('businessID')
      }
      
      this.$http
        .post(
          this.$cardbaseurl + "checkout/request-payment/",
          checkoutData
        )
        .then((response) => {
          this.paymenterrormsg = '';
          if(response.data.is_success == false){
            this.$router.push({ path: '/failure' }); // non 3ds false
          } else if (response.data.is_success == true && response.data.is_3ds == false) {
            this.$router.push({ path: '/success' }); // non 3ds success
          } else if (response.data.is_success == "None" && response.data["3ds"].enrolled === "Y") { // is.success = none // 3ds redirect  
            window.location.href = response.data["_links"].redirect.href; 
          } else {
            this.$router.push({ path: '/failure' });
          }
        })
        .catch((error) => {
          this.btnDesabled = false;
          // console.log(error.response.data.code);
          if(error.response.data.code == 'V0046-002'){
            this.paymenterrormsg = error.response.data.detail;//'Please provide valid country';
            shared.toastrError(this.paymenterrormsg);
          } else {
            this.paymenterrormsg = '';
            if(error.response.data.detail){
              shared.toastrError(error.response.data.detail);
            }else{
              shared.toastrError(error.response.data.message);
            }
          }
        });

    },

    updatePayment(paymentReq) {
      // console.log(paymentReq);
      let paymentData = {
        paymentrequest: paymentReq,
        paymentproviderid: null
      };
      // console.log(paymentData);
      this.$http
        .put(this.$standappurl + "payment/"+this.transactionId, paymentData)
        .then((res) => {
          console.log(res.data.code);
          // this.callpaymentdetail();
        })
        .catch((error) => {
          console.log(error.response.data.code);
        });
    },

    enterOnlyNumber(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },

    checkNameFormat(e, fieldName, elementName, elementIndex) {
      let char = e.target.value; //String.fromCharCode(e.keyCode); // Get the character
      let charLength = e.target.value.length;
      if (/^[A-Za-z'-\s]+$/.test(char)) {
        var splitStr = e.target.value.split(" ");
        for (var i = 0; i < splitStr.length; i++) {
          if (splitStr[0] != "")
            splitStr[i] =
              splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        this.paybyCard[fieldName] = splitStr.join(" ");
        if (charLength >= 2 && charLength <= 35 && splitStr[0] != "") {
          this.fieldErrorMsg = "";
          this.fieldName = "";
          if (this.errorArr.some((obj) => obj.index == elementIndex)) {
            this.errorArr[elementIndex].fieldName = "";
            this.errorArr[elementIndex].errorMessage = "";
            this.errorArr[elementIndex].index = elementIndex;
            //this.errorArr.push({'fieldName': '', errorMessage:'', 'index': elementIndex})
          }
          return true; // Match with regex
        } else {
          if (this.errorArr.some((obj) => obj.index == elementIndex)) {
            this.errorArr[elementIndex].fieldName = fieldName;
            this.errorArr[elementIndex].errorMessage =
              "Please provide minimum two characters without spaces at start.";
            this.errorArr[elementIndex].index = elementIndex;
            //this.errorArr.push({'fieldName': '', errorMessage:'', 'index': elementIndex})
          }
        }
      } else {
        this.paybyCard[fieldName] = char;
        this.fieldErrorMsg = "Please provide valid value for " + elementName;
        if (!this.errorArr.some((obj) => obj.index == elementIndex)) {
          for (let i = 0; i < elementIndex; i++) {
            if (this.errorArr[i] === undefined) {
              this.errorArr.push({ fieldName: "", errorMessage: "", index: i });
            }
            //if(this.errorArr[elementIndex].fieldName == '')
          }
          this.errorArr.push({
            fieldName: fieldName,
            errorMessage: this.fieldErrorMsg,
            index: elementIndex,
          });
        } else if (this.errorArr.some((obj) => obj.index == elementIndex)) {
          this.errorArr[elementIndex].fieldName = fieldName;
          this.errorArr[elementIndex].errorMessage = this.fieldErrorMsg;
          this.errorArr[elementIndex].index = elementIndex;
        } else {
          // do nothing
        }

        e.preventDefault(); // If not match, don't add to input text
      }
    },
    prefill: function (ccNum) {
      this.cardNumber = ccNum;
      this.$cardFormat.setCardType({
        currentTarget: this.$refs.cardNumInput,
        value: ccNum,
      });
    },
    getBrandClass: function (brand) {
      let icon = "";
      brand = brand || "unknown";
      let cardBrandToClass = {
        visa: "fa fa-cc-visa",
        mastercard: "fa fa-cc-mastercard",
        amex: "fa fa-cc-amex",
        discover: "fa fa-cc-discover",
        diners: "fa fa-cc-diners-club",
        jcb: "fa fa-cc-jcb",
        unknown: "fa fa-credit-card",
      };
      if (cardBrandToClass[brand]) {
        icon = cardBrandToClass[brand];
      }
      return icon;
    },

    setPrimaryNumberCountryCode(defaultCountry) {
      // console.log(defaultCountry);
      // this.$refs.telInput.choose(defaultCountry.primaryNumberCode);
      this.paybyCard.currency.name = defaultCountry.currency; //defaultCountry.primaryNumberCode == "GH" ? "GHS" : "GBP";
    },

    createLog(transactionid, pagename){
      let addLog = {
        transactionid : transactionid,
        pagename : pagename
      }
      this.$http.post(this.$standappurl + "payment/create/activitylog", addLog).then((response) => {
          console.log(response.data.code);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  computed: {
    cardBrandClass() {
      return this.getBrandClass(this.cardBrand);
    },
  },
  watch: {
    // handle auto-focus to next field
    // Note: since CVC can be 3 OR 4 digits we don't watch it
    cardNumber: function (val) {
      if (this.$cardFormat.validateCardNumber(val)) {
        // this.$refs.cardExpInput.focus();
      }
    },
    cardExpiry: function (val) {
      if (this.$cardFormat.validateCardExpiry(val)) {
        this.$refs.cardCvcInput.focus();
      }
    },

    checkout_country: function (result) {
      this.setPrimaryNumberCountryCode(result);
    },

    // merchant_details: function (data) {
    //   this.merchantdata = data;
    // }
  },
  onMounted() {
    this.$refs.cardNumInput.focus();
  },
  mounted() {
    if(sessionStorage.getItem('settranid')){
      this.transactionId = sessionStorage.getItem('settranid');
      // console.log(this.$emit(this.merchant_details.amount));
      // this.countryID = sessionStorage.getItem('country');
      this.createLog(sessionStorage.getItem('settranid'), 'Pay By Card');
    }
  }
};
</script>
<style>
.fa-lg {
  font-size: 30px !important;
}
</style>